
























































export default

	props: block: Object

	data: ->
		config:
			slidesPerPage: 1
			showDots: false
			showArrows: true
			gutter: 30

	computed:

		cards: -> (@block.cards || []).filter (card) -> !!card.id

		classes: -> [
			"layout-#{@block.splitFeatureLayout}"
		]

		bgStyle: ->
			backgroundColor: @block.backgroundColor


